.gradient-color {
  background: url("../../images/bg-slim-slice.jpg") repeat-x;
  background-size: contain;
  min-height: 100%;
  width: 100%;
  background-size: contain;
  display: block;

  .main-header {
    background: map-get($colors, primary);
    padding-top: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    transition: all 0.5s ease;
    box-shadow: $boxShadow;
    .header-bar {
      .content-wrapper {
        padding: 10px 0;
      }
      .top-nav {
        @media (max-width: 575px) {
          margin-top: 8px;
        }
        .nav-item {
          .dropdown-toggle {
            color: $white;
          }
          > a {
            color: $white;
          }
        }
      }
    }
  }

  .main-content {
    padding-top: 62px;
    transition: all 0.5s ease;
    background: url("../../images/bg-slice-sprite.png") repeat-x;
    background-color: $black;
    width: 100%;
    background-size: contain;
    display: block;
    min-height: 100vh;

    @media (max-width: 575px) {
      padding-top: 96px;
    }
  }
}

.b-border {
  border-bottom: 1px solid #edf1f9;
}

.max-w-370 {
  max-width: 376px;
  margin: 0 auto 30px;
}

.gift-card-section {
  padding: 40px 0;
}

.form-item {
  .info-label-text {
    font-family: map-get($fonts, secondary);
    font-size: 14px;
    color: #7a777c;
    padding: 5px 8px;
  }
}

.card-content-wrapper {
  .card-item {
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.02);
    max-width: 570px;
    margin: 0 auto;
    padding: 40px 60px;
    width: 100%;
    .btn-section {
      .info-text {
        a {
          color: #acb5cb;
          &:hover {
            color: map-get($colors, primary);
          }
        }
      }
    }
    h2 {
      letter-spacing: -0.03em;
    }
    .alert.alert-primary {
      border-radius: 8px;
      a {
        color: $black;
        &:hover,
        &:focus {
          color: map-get($colors, primary);
        }
      }
      .icon span {
        color: #e9344a;
      }
    }
    @media (max-width: 575px) {
      padding: 40px 30px;
      min-height: inherit;
      h2 {
        margin: 0 0 20px !important;
        font-size: 20px;
        letter-spacing: normal;
        line-height: 1.5;
      }
    }
  }

  &.max-w-1070 {
    .card-item {
      max-width: 1070px;
      .btn-link {
        margin-bottom: 10px;
        .back-btn {
          color: #687173 !important;
          font-family: map-get($fonts, secondary);
          font-weight: 600;
          svg {
            margin-right: 5px;
          }
          &:hover {
            color: map-get($colors, primary ) !important;
            text-decoration: underline;
          }
        }
        &:hover {
          text-decoration: none;
          span {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.max-w-740 {
    .card-item {
      max-width: 740px;
      .btn-link {
        margin-bottom: 10px;
        .back-btn {
          color: #687173 !important;
          font-family: map-get($fonts, secondary);
          font-weight: 600;
          svg {
            margin-right: 5px;
          }
          &:hover {
            color: map-get($colors, primary ) !important;
            text-decoration: underline;
          }
        }
        &:hover {
          text-decoration: none;
          span {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.choose-giftcard {
    .card-item {
      .card-holder {
        max-width: 800px;
        margin: 0 auto;
      }
      .search-form {
        .search-item-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .search-item {
          flex-grow: 1;
          position: relative;
          .form-control {
            background-color: transparent;
            padding-left: 50px;
            border-radius: 4px 0 0 4px !important;
            height: 47px;
          }
          .search-btn {
            position: absolute;
            top: 10px;
            left: 18px;
            color: #7d8597;
            width: 18px;
            i {
              display: inline-block;
            }
          }
        }
        .btn {
          border-radius: 0 4px 4px 0;
          height: 47px;
          width: 100%;
          min-width: 114px;
          @media (max-width: 991px) {
            min-width: 120px;
          }
          @media (max-width: 575px) {
            min-width: inherit;
          }
        }
      }

      .related-searches {
        .related-search-wrapper {
          display: flex;
          flex-wrap: wrap;
          .search-list-item {
            margin-right: 15px;
            margin-bottom: 15px;
            .btn {
              border-radius: 20px;
              font-size: 14px;
              font-weight: 500;
              padding: 0 20px;
              height: 38px;
              min-width: 95px;
              line-height: 1;
              &.active {
                background-color: map-get($colors, primary);
                border-color: map-get($colors, primary);
                color: $white;
              }
            }
          }
        }
      }

      h5 {
        color: #000;
        font-weight: 700;
        margin: 0 0 14px;
        font-size: 16px;
      }
      .category-searched-list {
        .image-holder {
          border-radius: 5px;
          margin-bottom: 10px;
          overflow: hidden;
          .box {
            background: #e7e7e7;
            min-height: 120px;
          }
        }
        .content-holder {
          h5 {
            font-size: 14px;
            color: map-get($colors, grey);
            margin: 0 0 4px;
            font-weight: 600;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
  }

  &.giftcard-detail {
    .gc-detail-title {
      padding-bottom: 30px;
      border-bottom: 1px solid #dfdfdf;
      h3 {
        line-height: 1.5;
      }
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .category-detail-item {
      margin-bottom: 25px;
      .content-holder {
        display: flex;
        @media (max-width: 575px) {
          flex-wrap: wrap;
        }
        .image-holder {
          margin-right: 30px;
          border-radius: 5px;
          margin-bottom: 10px;
          overflow: hidden;
          flex-shrink: 0;
          .box {
            background: #e7e7e7;
            min-height: 120px;
            min-width: 185px;
          }
          @media (max-width: 575px) {
            margin-right: 0;
            width: 100%;
            .box {
              min-width: 200px;
            }
          }
        }
        .content-desc {
          h5 {
            font-size: 14px;
            color: map-get($colors, grey);
            margin: 0 0 4px;
            font-weight: 600;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .gc-subtitle {
      margin-bottom: 30px;
      h4 {
        font-weight: 700;
        color: $black;
        margin-bottom: 5px;
        line-height: 1.5;
      }
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .gift-amount-section {
      background-color: #f3f7fa;
      border-radius: 32px;
      padding: 60px 70px;
      @media (max-width: 575px) {
        padding: 30px 15px;
      }
      .gc-amount-wrapper {
        text-align: center;
        h3 {
          margin-bottom: 30px;
          @media (max-width: 360px) {
            font-size: 20px;
          }
        }
      }
      .qunatity-section {
        display: flex;
        align-items: center;
        width: 200px;
        margin: 0 auto 30px;

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .input-item {
          position: relative;
          display: flex;
          align-items: flex-end;
          .currency {
            color: #bdbdbd;
            font-size: 36px;
            font-weight: 700;
            border-bottom: 1px solid #bdbdbd;
          }
          input {
            border: none;
            border-bottom: 1px solid #bdbdbd;
            background-color: transparent;
            pointer-events: none;
            font-size: 52px;
            line-height: 1;
            font-weight: 700;
            color: map-get($colors, content);
            width: 100%;
            padding-left: 10px;
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
        .value-button {
          cursor: pointer;
          padding: 4px 8px;
          display: inline-block;
          font-size: 24px;
          font-weight: 600;
          color: #bdbdbd;
        }
      }

      .quantity-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto 50px;
        .btn-item {
          padding: 0 8px;
          margin: 5px 0;
          .btn {
            border-radius: 17px;
            min-width: 76px;
            height: 34px;
            font-size: 14px;
            border-color: #f0eeee;
            &.active {
              background-color: map-get($colors, primary);
              border-color: map-get($colors, primary);
              color: $white;
            }
          }
        }
      }

      .submit-btn {
        @media (max-width: 575px) {
          .btn {
            font-size: 14px;
            height: 42px;
          }
        }
      }
    }
  }

  &.dNm-section {
    .card-item {
      padding: 60px;
      @media (max-width: 575px) {
        padding: 40px 20px;
      }
    }
  }
}

/*---Tab list content
--------------------------*/
.tab-list-section {
  .content-holder {
    p {
      font-size: 15px;
      color: #33415c;
      font-weight: 400;
    }
  }
  .nav-tabs {
    border-color: transparent;
    .nav-item {
      .nav-link {
        border-radius: 0;
        display: inline-block;
        padding: 0;
        margin-right: 20px;
        border: none;
        border-bottom: 4px solid transparent;
        font-size: 16px;
        font-family: map-get($fonts, secondary);
        font-weight: 600;
        color: #52b8fc;
        opacity: 0.5;
        &.active {
          border-bottom-color: map-get($colors, primary);
          opacity: 1;
        }
      }
      &:last-child {
        .nav-link {
          margin-right: 0;
        }
      }
    }
  }

  .form-group {
    label {
      font-size: 14px;
      color: #7d8597;
      font-weight: 500;
    }
    .form-control {
      height: 50px;
      border-radius: 32px;
      border: 1px solid #f2f2f2;
      background-color: $white;
      padding: 16px 24px;
      color: #33415c;
    }
  }

  .btn-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 575px) {
      margin-bottom: 30px !important;
    }
    .btn {
      min-width: 190px;
      @media (max-width: 575px) {
        min-width: 120px;
        height: 40px;
        font-size: 14px;
      }
    }
  }

  .link-item {
    font-size: 16px;
    font-weight: bold;
  }
}

/*---Delivery & Checkout Forms
-------------------------------*/
.delivery-detail-form,
.delivery-checkout-form {
  max-width: 370px;
  margin: 0 auto;
  h4 {
    color: $black;
  }
  .btn {
    @media (max-width: 380px) {
      font-size: 14px;
    }
  }

  .form-group {
    .form-control {
      background-color: $white;
      border-radius: 5px;
      padding: 15px 22px 15px 22px;
      height: 52px;
    }

    label {
      font-size: 14px;
      font-weight: 500;
    }

    textarea {
      height: auto;
      min-height: 140px;
      font-size: 14px;
      line-height: 1.5;
    }

    .info-label {
      font-size: 14px;
      color: map-get($colors, grey);
      padding: 5px 8px;
    }

    .input-item {
      position: relative;
      svg,
      img {
        position: absolute;
        right: 20px;
        top: 18px;
      }
      .form-control {
        padding-right: 40px;
      }
    }
  }
  .btn-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .link-item {
      font-size: 16px;
      font-weight: 400;
      &:hover {
        color: map-get($colors, primary);
      }
    }
  }
}

/*---Delivery Detail Form
----------------------------*/
.delivery-detail-form {
  .form-item {
    h4 span {
      font-weight: 400;
      color: #7d8597;
      font-size: 16px;
    }
  }
  .form-group {
    textarea.form-control {
      height: auto;
    }
    textarea.form-control.write-message {
      min-height: 165px;
    }
    textarea.form-control.record-video {
      min-height: 204px;
      background-color: #f5f9fb;
      border-color: #f5f9fb;
    }
    .textarea-item {
      position: relative;
      height: 100%;
      width: 100%;
      .btn-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        text-align: center;
        background-color: #f5f9fb;
        border-color: #f5f9fb;
        .fa-play {
          font-size: 50px;
          color: map-get($colors, primary);
          margin-bottom: 15px;
        //   cursor: pointer;
        }
        .info-text {
          font-size: 16px;
          font-family: map-get($fonts, secondary);
          font-weight: normal;
          color: map-get($colors, centent);
          cursor: pointer;
        }
      }
    }
  }
}

/*---Delivery Checkout Form
----------------------------*/
.delivery-checkout-form {
  .v-list {
    > * {
      margin-bottom: 5px;
    }
    .title-info,
    .email-info {
      font-size: 16px;
      color: $black;
    }
    .email-info span {
      margin-left: 6px;
      display: inline-block;
    }
  }

  .action-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    .btn-item,
    .title-info {
      color: map-get($colors, grey);
      font-weight: 500;
      font-size: 14px;
      display: inline-block;
      font-family: map-get($fonts, primary);
    }
    .btn-item {
      &:hover {
        color: map-get($colors, primary);
      }
    }
  }

  /*---Apply Giftcard
    ----------------------------*/
  .apply-giftcard {
    .content-holder {
      display: flex;
      align-items: center;
      @media (max-width: 575px) {
        flex-wrap: wrap;
      }
      .image-holder {
        margin-right: 30px;
        border-radius: 5px;
        margin-bottom: 10px;
        overflow: hidden;
        flex-shrink: 0;
        .box {
          background: #e7e7e7;
          min-height: 120px;
          min-width: 185px;
        }
        img {
          max-width: 185px;
        }
        @media (max-width: 575px) {
          margin-right: 0;
          width: 100%;
          .box {
            min-width: 200px;
          }
        }
      }

      .content-desc {
        .btn-item {
          margin-bottom: 25px;
          text-align: right;
          @media (max-width: 575px) {
            text-align: left;
            margin-bottom: 10px;
          }
          a {
            font-family: map-get($fonts, primary);
            font-size: 14px;
            font-weight: 500;
            &:hover {
              color: map-get($colors, primary);
            }
          }
        }
        h4 {
          margin-bottom: 8px;
        }
        .price-value {
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
  }

  /*---Video Message Page
    ----------------------------*/
  .video-message-wrapper {
    background-color: $white;
    border-radius: 16px;
    min-height: 203px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .action-btn {
        padding: 0 30px;
        cursor: pointer;
        @media (max-width: 480px) {
          padding: 0 15px;
        }
        img {
          display: block;
          margin: 0 auto 20px;
        }
      }
      .title-info {
        display: block;
        text-align: center;
        font-family: map-get($fonts, secondary);
        font-size: 16px;
      }
    }
  }

  /*---Apply Conditions
    ----------------------------*/
  .apply-conditions {
    h5 {
      span {
        font-weight: 400;
        display: inline-block;
      }
      font-size: 14px;
      color: map-get($colors, grey);
      font-weight: 600;
      margin-bottom: 6px;
      a {
        color: map-get($colors, grey);
        &:hover,
        &:focus,
        &:active {
          color: map-get($colors, primary);
        }
      }
    }
    p {
      font-size: 14px;
      color: map-get($colors, grey);
      font-weight: 400;
      a {
        color: map-get($colors, grey);
        &:hover,
        &:focus,
        &:active {
          color: map-get($colors, primary);
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  /*---Payment Methods Button
    ----------------------------*/
  .payment-method-btns {
    @media (max-width: 480px) {
      margin-bottom: 5px !important;
    }
    .h-list {
      @media (max-width: 480px) {
        flex-wrap: wrap;
        .btn {
          margin: 0 0 10px;
        }
      }
    }
    .btn {
      &.btn-transparent {
        border-color: map-get($colors, grey);
      }
      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: map-get($colors, primary);
        color: $white;
      }
    }
  }

  /*---Button Section
    ----------------------------*/
  .btn-section {
    .btn {
      min-width: 185px;
    }
  }

  .form-item {
    .info-label-text {
      font-size: 14px;
      font-family: map-get($fonts, secondary);
    }
    .bg-color {
      .form-control {
        background-color: #f5f9fb;
        color: #33415c;
      }
    }
  }
}
