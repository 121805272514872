/*---footer
--------------------------*/
.footer {
  display: flex;
  min-height: 214px;
  background-color: #f3f7fa;
  padding: 10px 0;
  .container {
    display: inline-block;
    vertical-align: middle;
    margin: auto;
    height: 100%;
    // .row{
    //     min-height: 214px;
    // }
  }

  .footer-menu-item {
    h5 {
      font-size: 14px;
      font-weight: 700;
      color: #7d8597;
      font-family: map-get($fonts, secondary);
      margin-bottom: 14px;
    }
    ul {
      li {
        margin-bottom: 10px;
        a {
          color: #7d8597;
          font-size: 14px;
          font-weight: 400;
          font-family: map-get($fonts, secondary);
        }
        div {
          padding-left: 22px;
        }
      }
    }
  }
}
