.max-w-600{
    max-width: 600px;
    margin: 0 auto;
}

.max-w-410{
    max-width: 410px;
    margin: 0 auto;
}

.gift-sent-card{
    width: 100%;
    text-align: center;
    .card-white-holder {
        max-width: 570px;
        margin: 0 auto;
        min-height: 605px;
        display: flex;
        align-items: center;
        @media(max-width:575px){
            min-height: 520px;
        }
    }
}

.social-btn{
    .btn{
        i{color: map-get($colors, primary); font-size: 16px;}
        padding: 10px 24px;
        height: 51px;
        border-radius: 32px;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        font-family: map-get($fonts, secondary );
        &:hover,&:focus,&:active{
            i{color:$white;} 
        }
    }
}

.divider-text {
    position: relative;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        border-bottom: 1px solid #EDF1F9;
        top: 55%;
        left: 0;
        z-index: 1;
    }
    span{
        padding: 7px 10px;
        font-size: 16px;
        position: relative;
        z-index: 2;
        background-color: #fff;
        color: #7D8597;
    }
}

.apply-conditions{
    .custom-checkbox {
        span{
            padding-top: 0;
            color: map-get($colors, grey );
            font-size: 14px;
            line-height: 22px;
            &:after{border-radius:100%;}
        }
        input{
            &:checked+span:after{
                background-color: map-get($colors, primary);
            }
            &:checked+span:before{
                top: 5px;
                left: 5px;
                font-size: 10px;
                line-height: 1;
            }
        }
    }
}

.card-block-title{
    h1{margin-bottom: 0; font-size: 26px; line-height: 1.2; color: #33415C; font-weight: 600; font-family: map-get($fonts, primary);}
    h2{font-size: 32px; font-family: map-get($fonts, secondary ); text-align: center; font-weight: bold;}
    p a{ font-weight: 700;}
    strong {color: map-get($colors, grey);}
}

.btn-holder{
    font-size: 16px; margin-bottom: 30px;
    @media(max-width:575px){
        margin-bottom: 0;
    }
    a{
        font-size: 14px; font-weight: 600;
        &:hover,&:focus,&:active{
            color: map-get($colors, primary );
        }
    }
    span{display:inline-block; margin-left: 10px; font-size: 16px; font-family: map-get($fonts, secondary); color: #7D8597;}
}

.card-content-box {
    max-width: 570px;
    margin: 0 auto;
    .icon-holder{
        img{display: block; margin: 0 auto;}
    }
    .info-text{
        a{
            color: #ACB5CB;
            &:hover,&:focus{
                color: map-get($colors, primary );
            }
        }
    }
}

.card-white-holder {
    padding: 40px;
    background-color: $white;
    box-shadow:0px 0px 8px 1px rgba(0, 0, 0, 0.024);
    border-radius: 8px;
    .btn-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .btn{min-width: 185px;}
    }

    @media(max-width:991px){
        padding: 20px;
    }
    .card-item {
        max-width: 100%;
        .btn-holder{
            display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;
            font-size: 14px; margin-bottom: 30px;
            @media(max-width:575px){
                margin-bottom: 0;
            }
            a{
                color: map-get($colors,grey); font-size: 14px; font-weight: 600;
                &:hover,&:focus,&:active{
                    color: map-get($colors, primary );
                }
            }
            span{display:inline-block; margin-left: 10px; font-size: 14px; font-family: map-get($fonts, secondary); color: #7D8597;}
        }
        .card-block-wrapper {
            @media(max-width:575px){
                margin-bottom: 0;
            }
            .icon-holder {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;
                overflow: hidden;
                img{
                    display: block; margin:0 auto;
                    // border-radius: 100%;
                    max-height: 204px;
                    max-width: 204px;
                    object-fit: cover;
                    overflow: hidden;
                }
            }
        }
        .card-block-title{
            h1{margin-bottom: 0; font-size: 28px; letter-spacing: -0.02em; line-height: 1.2; color: $black; font-weight: 800; font-family: map-get($fonts, primary );}
            h2{font-size: 32px; font-family: map-get($fonts, secondary ); text-align: center; font-weight: bold;}
            p a{font-weight: 700;}
            strong {color: map-get($colors, grey);}
        }

        .social-btn{
            .btn{
                i{color: map-get($colors, primary);}
                padding: 10px 24px;
                height: 51px;
                border-radius: 32px;
                justify-content: flex-start;
                align-items: center;
                font-size: 14px;
                font-weight: 400;
                &:hover,&:focus,&:active{
                   i{color:$white;} 
                }
            }
        }

        .divider-text {
            position: relative;
            text-align: center;
            margin-top: 15px;
            margin-bottom: 15px;
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                border-bottom: 1px solid map-get($colors,grey);
                top: 55%;
                left: 0;
                z-index: 1;
            }
            span{
                padding: 7px 10px;
                font-size: 16px;
                position: relative;
                z-index: 2;
                background-color: #f4f4f4;
            }
        }

        .form-group{
            label{font-weight: 500; font-size: 14px; color: $black;}
            .form-control{
                height: 48px;
                border-radius: 5px;
                background-color: $white;
                color: map-get($colors, grey );
            }
        }
    }
    .info-text{
        a{color: map-get($colors, grey); font-size: 16px;}
    }
}

.card-flex{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .card-item{
        width: 100%;
    }
    .max-w-600{
        width: 100%;
        padding: 20px 0;
    }
}

.upload-file-wrapper{
    .drag-wrapper{
        margin-bottom: 25px;
    }

    .card{
        padding: 10px;
        border: none;
        max-width: calc(100% - 98px);
        margin-left: 20px;
        border-radius: 0px 0px 32px 32px;
        background-color: #F8FAFC;
        @media(max-width:480px){
            max-width: calc(100% - 35px);
        }
        .card-desc{
            padding: 0 15px 20px;
            h5{margin-bottom: 0; font-size: 14px; font-weight: 600;}
            p{font-size: 14px; margin-bottom: 0; line-height: 18px; max-width: 250px;}
        }
    }

    .card-upload{
        .card-item{
            border-radius: 32px; padding: 16px 24px; flex-grow: 1;
            background-color:#F8FAFC; color:#7D8597; min-height: 51px; margin-right: 10px;
            display: flex; align-items: center;
            cursor: pointer;
            @media(max-width:480px){
                padding: 15px 12px;
                margin-right: 5px;
            }
            svg{
                margin-right: 10px; display: inline-block;
                color: map-get($colors, primary);
                @media(max-width:480px){
                    margin-right: 5px;
                }
            }
            span{
                font-size: 16px; display: inline-block; color: map-get($colors, grey );
                @media(max-width:480px){
                    font-size: 12px;
                }
            }
        }
        .icon-holder{
            margin-bottom: 0 !important; cursor: pointer;
            height: 100%; width: 48px;
            @media(max-width:480px){
                width: 30px;
            }
        }
        .upload-files{
            background: #fff;
        }
        &[aria-expanded="true"]{
            .card-item {
                background-color: map-get($colors, primary);
                svg{color: $white;}
                span {color: $white;}
            }
        }
    }

    .upload-list-nav{
        .file-progress-info{
            justify-content: space-between;
            margin-bottom: 5px;
            .file-progress{
                justify-content: space-between;
                align-items: center;
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                line-height: 1.5;
                @media(max-width:480px){
                    font-size: 12px;
                }
                span{flex-grow: 1; display: inline-block; margin-right: 5px;}
            }
            .fas{color: map-get($colors, success);}
        }
        .progress{
            height: 3px;
            .progress-bar{background-color: #6FCF97;}
        }
    }
}


.search-recipient{
    .info-text a{
        color: #ACB5CB;
        &:hover{
            color: map-get($colors, primary);
        }
    }
    h4{color: $black;}
    .card-block-title{
        display: flex; align-items: center;
        justify-content: space-between;
        svg{
            color: map-get($colors, primary);
        }
    }
    .search-holder{
        position: relative;
        @media(max-width:991px){
            width: 100%;
            margin-bottom: 20px;
        }
        i{
            position: absolute;
            left: 16px;
            top: 18px;
            cursor: pointer;
        }
        input{
            background-color: transparent; padding-left: 40px; width: 100%; min-width: 370px;
            border-radius: 4px;
            font-size: 16px; font-weight: 300; border: 1px solid rgba(129, 131, 134, 0.3);
            @media(max-width:991px){
                min-width: 100%;
            }
        }
    }

    .upload-contact-wrapper{
        .v-list{flex-direction:row;}
        .boxscroll{
            max-height: 320px;
            margin-bottom: 30px;
        }
        .card-item{
            display: flex;
            align-items: center;
            margin-right: 10px;
            box-shadow: none;
            border-radius: 0;
            padding: 0;
            margin-bottom: 8px;
            .icon-holder{
                width: 48px;
                height: 48px;
                border-radius: 100%;
                border: 1px solid #ACB5CB;
                margin-right: 10px;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                img{max-width: 25px;}
                flex-shrink: 0;
            }

            .card-desc{
                border-radius: 32px;
                background-color: #F8FAFC;
                padding: 16px 20px;
                font-size: 12px;
                line-height: 1.4;
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                @media(max-width:480px){
                    flex-wrap: wrap;
                    padding: 10px 12px;
                    font-size: 12px;
                }
                strong{font-weight: 600; letter-spacing: -0.04em; margin-right: 5px; display: block; width: 100%;}
            }
        }
    }
}
