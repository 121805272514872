.title-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;
  h2 {
    margin-bottom: 0;
  }
  &.b-border {
    padding-bottom: 20px;
    border-bottom: 1px solid #edf1f9;
    h5 {
      margin-bottom: 0 !important;
    }
  }
}
/*---Intro Banner
--------------------------*/
.intro-banner {
  #my-canvas {
    position: absolute;
    z-index: -11;
    width: 100%;
    // height: 100%;
  }
  .banner-section {
    max-width: 980px;
    margin: 0 auto;
    min-height: calc(100vh - 78px);
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 575px) {
      min-height: calc(100vh - 124px);
    }
    .content-holder {
      text-align: center;
      h1 {
        font-size: 55px;
        color: #2c2e2f;
        font-weight: 800;
        line-height: 92px;
        margin-bottom: 20px;
        @media (max-width: 575px) {
          font-size: 44px;
          line-height: 60px;
        }
      }
      .slogan {
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 40px;
        color: map-get($colors, content);
        @media (max-width: 575px) {
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
  }
}

/*---Features
--------------------------*/
.gif-features {
  background-color: #f3f7fa;
  padding: 80px 0 70px;
  @media (max-width: 575px) {
    padding: 40px 0;
    margin-bottom: 25px;
  }
  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -15px;
    .gift-content-item {
      width: auto;
      padding: 0 15px;
      @media (max-width: 575px) {
        margin-bottom: 25px !important;
        margin: 0 auto;
        text-align: center;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .content-holder {
        max-width: 160px;
        @media (max-width: 575px) {
          max-width: 100%;
        }
        .icon-holder {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
        }
        .title {
          font-size: 16px;
          font-weight: 700;
        }
        .description {
          font-weight: normal;
          font-family: map-get($fonts, secondary);
          text-align: center;
        }
      }
    }
  }
}

/*---Popular Cards
--------------------------*/
.popular-cards {
  padding: 118px 0 92px;
  .search-category-holder {
    .card-item {
      border-radius: 16px;
      margin-bottom: 25px;
      .icon-holder {
        img {
          width: 100%;
        }
      }
    }
    @media (max-width: 575px) {
      [class*="col-"] {
        width: 100%;
      }
    }
  }
}

/*---Enlighten Section
--------------------------*/
.enlighten-section {
  background: #070707;
  background-size: cover;
  min-height: 550px;
  width: 100%;
  display: block;
  .container {
    min-height: 550px;
    display: flex;
    align-items: center;
  }
  .content-holder {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 575px) {
      padding: 20px 0;
    }
    .image-holder {
      width: 38%;
      margin-right: 30px;
      @media (max-width: 575px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    .content-description {
      width: 62%;
      max-width: 500px;
      color: $white;
      h2 {
        color: $white;
      }
      .action-btns {
        display: flex;
        align-items: center;
        .link-item {
          margin-right: 25px;
          color: map-get($colors, primary);
          &:hover {
            color: map-get($colors, tertiary);
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      @media (max-width: 991px) {
        width: 55%;
      }
      @media (max-width: 575px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

/*---Search Form
--------------------------*/
.search-form {
  .select-item {
    .form-control {
      border-radius: 0 !important;
    }
  }
}

/*---Gift Ideas
--------------------------*/
.popular-gift-ideas {
  padding: 50px 0 50px;
  .gift-ideas-holder {
    position: relative;
    margin: 0 auto 25px;
    @media (max-width: 767px) {
      width: 290px;
    }
    .figure-item {
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #f3f7fa;
      margin: 0 auto;
      min-height: 428px;
      display: block;
      border-radius: 12px;
      @media (max-width: 767px) {
        background-position: center top;
      }
    }
    .figure-caption {
      position: absolute;
      right: 15px;
      bottom: 15px;
      h2 {
        font-weight: 700;
        color: $white;
        margin-bottom: 0;
        text-align: right;
      }
    }
  }
}

/*---About Sharebear
--------------------------*/
.about-sharebear {
  padding: 75px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  .about-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .left-content {
      flex-grow: 1;
      width: 30%;
      @media (max-width: 767px) {
        width: 40%;
      }
      @media (max-width: 575px) {
        width: 100%;
        text-align: center;
      }
      .image-holder {
        margin-right: 60px;
        img {
          width: 100%;
        }
        @media (max-width: 575px) {
          margin: 0 auto 40px;
          img {
            max-width: 280px;
            margin: 0 auto;
          }
        }
      }
    }
    .right-content {
      width: 70%;
      @media (max-width: 767px) {
        width: 60%;
      }
      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
}

/*---Brand Section
--------------------------*/
.brand-section {
  padding: 75px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  .brand-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 -10px;
    .brand-item {
      width: 223px;
      height: 223px;
      border-radius: 100%;
      overflow: hidden;
      background-color: #f3f7fa;
      margin: 0 8px 16px;
      .circle-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }
}
