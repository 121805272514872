::-webkit-input-placeholder {
    color: #666666;
    opacity: 1;
}

::-moz-placeholder {
    color: #666666;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #666666;
    opacity: 1;
}

:-moz-placeholder {
    color: #666666;
    opacity: 1;
}

img {
    max-width: 100%;
}

a{
    color: map-get($colors, tertiary);
    font-family: map-get($fonts, secondary);
    font-weight: 600;
    text-decoration: none;
    font-size: 16px;
}

a.btn.disabled,
fieldset:disabled a.btn {
    filter: grayscale(100%);
    opacity: 0.5;
}

textarea {
    resize: vertical;
}

textarea.form-control {
    height: 100px;
    padding: 1.2rem;
    resize: none;
    border-radius: 16px !important;
}

.custom-checkbox {
    display: inline-flex;
    position: relative;
    float: none;
    font-weight: 400;
}

.custom-checkbox input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 100;
}

.custom-checkbox span {
    display: block;
    padding-left: 30px;
    padding-top: 0;
    line-height: 22px;
    color: #7A777C;
    position: relative;
}

.custom-checkbox span:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: solid 1px #C4C4C4;
}

.custom-checkbox span:before {
    content: "\f00c";
    color: map-get($colors, primary);
    opacity: 0;
    position: absolute;
    top: 3px;
    left: 4px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 14px;
    z-index: 1;
}

.custom-checkbox input:checked+span:after {
    background-color: map-get($colors, grey);
    border-color: $white;
    color: $white;
}
.custom-checkbox input:checked+span:before {
    opacity: 1;
    color: $white;
}

.last-margin-0>*:last-child {
    margin-bottom: 0 !important;
}

.control-label {
    color: map-get($colors, primary);
    margin: 0 0 10px;
    line-height: 1;
    display: block;
    font-weight: 600;
}

.control-label.required::after {
    content: "*";
    color: #ff4545;
    margin-left: 5px;
}

.form-group{
    label{
        font-weight: 500;
        font-family: map-get($fonts, secondary);
        color: #33415C;
        font-size: 14px;
    }
}

.form-control {
    height: 51px;
    border: 1px solid #EDF1F9;
    border-radius: 32px !important;
    color: rgba(68, 68, 68, 0.5);
    padding: 16px 20px 16px 24px;
    border-radius: 32px;
    font-size: 16px;
    line-height: 24px;
    background: $white;
    font-family: map-get($fonts , secondary );
    font-weight: normal;

    &:invalid {
        border-color: #ff9f9f;
    }

    &::-moz-placeholder {
        color: #b3bbbb;
        opacity: 1;
    }

    &::-webkit-input-placeholder {
        color: #b3bbbb;
        opacity: 1;
    }
}

.input-item{
    position: relative;
    > img{
        position: absolute;
        right: 20px;
        top: 18px;
    }
}

select.form-control {
    padding: 5px 35px 5px 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
}

select.form-control:not([size]):not([multiple]) {
    height: 47px;
    background-color: transparent;
    position: relative;
    z-index: 1;
}

.select-item{
    position: relative;
    &:after{
        content: "\f107";
        color: rgba(68, 68, 68, 0.5);
        font-family: 'Font Awesome 5 Free';
        display: inline-block;
        font-size: 18px;
        line-height: 1;
        font-weight: 900;
        vertical-align: middle;
        border: none;
        position: absolute;
        right: 12px;
        top: 14px;
    }
}

select::-ms-expand {
    display: none;
}

.flex-grow-1 {
    flex-grow: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background: #eff5f5;
    border: 0;
}

.form-field-group {
    margin-bottom: 30px;

    &.required .control-label:after {
        content: "*";
        color: #ff4545;
        margin-left: 5px;
    }

    .notes {
        display: block;
        line-height: 1;
        text-align: right;
        color: #ff7272;
        font-size: 13px;
        padding-top: 5px;
        display: none;
    }

    .display-info {
        display: block;
        line-height: 1;
        text-align: right;
        color: #c4c4c4;
        font-size: 13px;
        padding-top: 5px;
    }

    .form-control:invalid+.notes {
        display: block;
    }
}

.btn {
    line-height: 1;
    height: 52px;
    min-width: 140px;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;
    transition: all 0.4s ease;
    box-shadow: none;
    font-weight: 500;
    &.btn-rounded{
        border-radius: 4px;
        height: 42px;
        padding: 10px 24px;
        font-size: 14px;
        font-weight: 600;
    }
    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
        outline: none;
    }
}

.btn-default {
    background-color: map-get($colors, grey);
    color:#EDFBFF;
    min-width: 120px;
    border-radius: 5px;
    &:active,
    &:focus,
    &:hover {
        background-color: darken(map-get($colors, grey), 5%);
        color: $white;
    }
}

.btn-primary {
    background: $primaryColor;
    color: $white;
    border-radius: 4px;
    font-weight: bold;
    font-family: map-get($fonts, secondary);
    &:active,
    &:focus,
    &:hover {
        background-color: $primaryColor;
    }
}

.btn-secondary {
    background: map-get($colors, secondary);
    color: $white;
    border-radius: 32px;
    font-weight: bold;
    font-family: map-get($fonts, secondary);
    &:active,
    &:focus,
    &:hover {
        background-color: $primaryColor;
    }
}

.btn-transparent {
    background-color: transparent;
    color: map-get($colors, grey);
    border: 1px solid #f2f2f2;
    &:active,
    &:focus,
    &:hover,
    &.active,
    &.focus,
    &.hover {
        background-color: map-get($colors, primary);
        border-color: map-get($colors, primary);
        color: $black;
    }
}

.btn-white {
    background-color: $white;
    color: map-get($colors, grey);
    border: 1px solid #EDF1F9;;
    &:active,
    &:focus,
    &:hover {
        background-color: map-get($colors, primary);
        border-color: map-get($colors, primary);
        color: $white;
    }
}

.btn-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width:575px){
        margin-bottom: 30px !important;
    }
    > .btn{
        min-width: 190px;
        @media(max-width:575px){
            min-width: 120px;
            height: 40px;
            font-size: 14px;
        }
    }
    .back-btn { 
        color: #687173 !important;
        &:hover,&:focus {
            color: map-get($colors, primary ) !important;
        }
    }
}
.h-list {
    display: flex;
    align-items: center;
    @include sameValue(margin padding, 0);

    >* {
        line-height: 1;
        margin-right: 15px;
        min-width: 0;
        list-style-type: none;
        position: relative;
    }

    @for $i from 0 through 30 {
        &.space-between-#{$i}>* {
            margin-right: $i#{px};
        }
    }

    >*:last-child {
        margin-right: 0 !important;
    }
}

.v-list {
    @include sameValue(margin padding, 0);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    >* {
        flex-grow: 1;
        line-height: 1;
        margin: 0 0 15px;
        list-style-type: none;
        position: relative;
    }

    @for $i from 0 through 30 {
        &.space-between-#{$i}>* {
            margin-bottom: $i#{px};
        }
    }

    >*:last-child {
        margin-bottom: 0 !important;
    }
}

.slick-dots {
    display: flex;
    width: 100%;
    position: absolute;
    @include sameValue(padding margin left, 0);
    justify-content: center;
    bottom: 16px;

    >li {
        margin-right: 3px;
        list-style-type: none;
    }

    >li button {
        display: block;
        border: 0;
        outline: none;
        font-size: 0;
        padding: 0;
        @include sameValue(width height border-radius, 6px);
        background: #242424;
    }

    >li.slick-active button {
        background: white;
    }

    >li:last-child {
        margin-right: 0;
    }
}

.slick-instance {
    position: relative;
}

.slick-slide {
    outline: none;
}

.overlay {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s ease;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: rgba(black, 0.8);
    z-index: 10011;
}


.font-medium {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.overflow-hidden {
    overflow: hidden;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
    float: right;
}

.dropdown{
    .dropdown-toggle{
        display: inline-flex;
        min-width: 70px;
        span{display: inline-block;}
        &:after{border: none;}
        &:before{
            content: "\f107";
            font-family: 'Font Awesome 5 Free';
            display: inline-block;
            font-size: 18px;
            line-height: 1;
            font-weight: 900;
            vertical-align: middle;
            border: none;
            position: absolute;
            right: 0;
            top: 4px;
        }
        &:hover,&:focus,&:active{
            box-shadow: none !important;
            background: transparent;
            color: map-get($colors, primary);
        }
    }
    .dropdown-menu {
        border-radius: 0;
        background: $white;
        border: none;
        box-shadow: $boxShadow;
        font-size: 16px;
        margin: 0;
        min-width: 155px;
        padding: 5px 0;

        > li {
            margin: 0;
            line-height: 1;
            &.dropdown-item{
                padding: 10px 12px;
                font-size: 14px;
                i{margin-right: 10px;}
                &:hover,&:focus,&:active{
                    outline: none;
                    background: rgba(10, 37, 104, 0.07);
                    color: map-get($colors, primary);
                }
            }

            > a {
                display: block;
                font-size: 14px;
                padding: 10px 12px;
                font-family: map-get($fonts, primary);
                font-weight: 500;
                color: map-get($colors, content);
                &:hover,&:focus,&:active{
                    background: rgba(10, 37, 104, 0.07);
                    color: map-get($colors, primary);
                }
            }
        }
        &.show{
            margin-top: 8px;
        }
    }
}

.bg-color-grey{
    background-color: #F8FAFC !important;
}

.breadcrumbs-wrapper{
    padding: 15px 0;
}

.breadcrumb-list{
    li{
        margin-right: 0;
        a{
            color: map-get($colors, content); font-size: 14px; font-weight: 400;
            &:after{
                content: "/";
                padding: 0 7px;
                display: inline-block;
            }
        }
        span{
            color: map-get($colors, primary); font-size: 14px;
        }
    }
}

.container {
    max-width: 980px;
    @media(max-width:767px){
        max-width: 100%;
    }
}

.boxscroll{
    max-height: 195px;
    overflow: auto;
    margin-bottom: 25px;
}


.nicescroll-cursors{
    border-radius: 0 !important;
}


.action-btn-section{
    .btn-item{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        border-radius: 100%;
        line-height: 1;
        text-align: center;
        font-size: 30px;
        transition: all 1s ease;
        background-color: $white;
        box-shadow: $boxShadow;
        color: #818386;
        border: 1px solid #818386;
        text-decoration: none;
        position: fixed;
        bottom: -60px;
        right: 20px;
        &:hover,&:focus {
            background-color:map-get($colors, primary);
            border-color: map-get($colors, primary);
            color: $white;
        }

        &.arrow-go-top.show {
            bottom: 20px;
        }
    }
}

.alert{
    padding: 30px 40px;
    border-radius: 8px;
    border: none;
    align-items: center;
    &.alert-primary{
        background-color:#E7E7E7;
        color: map-get($colors, grey);
    }
    a{
        color: map-get($colors, grey); font-family: map-get($fonts, secondary );
        font-weight: 600;
        &:hover,&:focus,&:active{color: map-get($colors,primary);}
    }
    p:last-child{margin-bottom: 0;}

    .icon > span{
        font-size: 24px;
        font-weight: 600;
        line-height: 1;
        display: inline-block;
    }
}

.files input {
    opacity: 0;
    min-height: 158px;
    cursor: pointer;
    @media(max-width:575px){
        min-height: 130px;
    }
}
.files input:focus{
    background-color: $white;
 }
.files{ position:relative; background-color: $white; text-align: center; border-radius: 5px;}
.files .content {
    position: absolute;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    margin: 0 auto;
    color: map-get($colors, grey);
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    span{display: inline-block; text-transform: initial; font-size: 14px;}
    @media(max-width:575px){
        font-size: 12px;
    }
}

/*---Search Giftcard
--------------------------*/
.search-gift-card{
    padding: 100px 0 40px;
    @media(max-width:991px){
        padding: 40px 0;
    }
    .search-form{
        .search-item{
            position: relative;
            .form-control{
                background-color: transparent;
                padding-left: 50px;
                border-radius:4px 0 0 4px !important;
                height: 47px;
            }
            .search-btn{
                position: absolute;
                top: 10px;
                left: 18px;
                color: #7D8597;
                width: 18px;
                i{display: inline-block;}
            }
            @media(max-width:575px){
                .form-control{border-radius: 4px; margin-bottom: 15px;}
            }
        }
        .btn{
            border-radius: 0 4px 4px 0; height: 47px; width: 100%;
            @media(max-width:991px){
                min-width: 100%;
            }
            @media(max-width:575px){
                border-radius: 4px;
            }
        }
        @media(max-width:575px){
            .select-item{
                margin-bottom: 15px;
                &:after{right: 22px;}
                .form-control{
                    border-radius: 4px;
                }
            }
        }
    }

    .relevant-searches{
        .relevant-search-wrapper{
            display: flex;
            flex-wrap: wrap;
            .search-card-item{
                margin-right: 15px;
                margin-bottom: 15px;
                .btn{
                    border-radius: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    padding: 0 20px;
                    height: 38px;
                    min-width: 114px;
                    line-height: 1;
                }
            }
        }
    }

    .search-category-section{
        h5{
            font-size: 16px; font-weight: 700;
        }
        .icon-holder{
            margin-bottom: 20px;
            img{width: 100%;}
        }
        .search-category-holder{
            @media(max-width:575px){
                [class*="col-"]{
                    width: 50%;
                }
            }
            .card-item{
                border-radius: 16px;
                margin-bottom: 25px;
                .card-title{
                    font-size: 15px;
                    font-weight: 700;
                    color: #7A777C;
                    @media(max-width:575px){
                        font-size: 12px;
                    }
                }
            }
        }
    }
}