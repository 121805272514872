@mixin pring_properties($className, $prop, $from, $top){
    @for $i from $from through $top {
        .#{$className}-#{$i}{#{$prop}: $i#{px} !important;}
    }
}

@include pring_properties("font-size", "font-size", 10, 60);
@include pring_properties("height", "height", 0, 30);

@include pring_properties("mt", "margin-top", 0, 70);
@include pring_properties("mr", "margin-right", 0, 70);
@include pring_properties("mb", "margin-bottom", 0, 70);
@include pring_properties("ml", "margin-left", 0, 70);

@include pring_properties("pt", "padding-top", 0, 70);
@include pring_properties("pr", "padding-right", 0, 70);
@include pring_properties("pb", "padding-bottom", 0, 70);
@include pring_properties("pl", "padding-left", 0, 70);

@for $i from 0 through 70 {
    .grid-gap-#{$i} {margin-bottom: -$i#{px}; position: relative;}
    .grid-gap-#{$i} > *{margin-bottom: $i#{px};}

    .grid-gutters-#{$i}{
        // margin-top: -$i#{px} !important;
        margin-right: -$i#{px} !important;
        // margin-bottom: -$i#{px} !important;
        margin-left: -$i#{px} !important;
    }
    .grid-gutters-#{$i} > *{
        //padding-top: $i#{px} !important;
        padding-right: $i#{px} !important;
        //padding-bottom: $i#{px} !important;
        padding-left: $i#{px} !important;
    }
}

@media screen and (max-width: 1199px){
    @for $i from 0 through 70{
        .mt-#{$i}-md {margin-top: $i#{px} !important;}
        .mr-#{$i}-md {margin-right: $i#{px} !important;}
        .mb-#{$i}-md {margin-bottom: $i#{px} !important;}
        .ml-#{$i}-md {margin-left: $i#{px} !important;}

        .pt-#{$i}-md {padding-top: $i#{px} !important;}
        .pr-#{$i}-md {padding-right: $i#{px} !important;}
        .pb-#{$i}-md {padding-bottom: $i#{px} !important;}
        .pl-#{$i}-md {padding-left: $i#{px} !important;}
    }
}
@media screen and (max-width: 991px){
    @for $i from 0 through 70 {
        .mt-#{$i}-sm {margin-top: $i#{px} !important;}
        .mr-#{$i}-sm {margin-right: $i#{px} !important;}
        .mb-#{$i}-sm {margin-bottom: $i#{px} !important;}
        .ml-#{$i}-sm {margin-left: $i#{px} !important;}

        .pt-#{$i}-sm {padding-top: $i#{px} !important;}
        .pr-#{$i}-sm {padding-right: $i#{px} !important;}
        .pb-#{$i}-sm {padding-bottom: $i#{px} !important;}
        .pl-#{$i}-sm {padding-left: $i#{px} !important;}
    }
}
@media screen and (max-width: 767px){
    @for $i from 0 through 70 {
        .mt-#{$i}-xs {margin-top: $i#{px} !important;}
        .mr-#{$i}-xs {margin-right: $i#{px} !important;}
        .mb-#{$i}-xs {margin-bottom: $i#{px} !important;}
        .ml-#{$i}-xs {margin-left: $i#{px} !important;}

        .pt-#{$i}-xs {padding-top: $i#{px} !important;}
        .pr-#{$i}-xs {padding-right: $i#{px} !important;}
        .pb-#{$i}-xs {padding-bottom: $i#{px} !important;}
        .pl-#{$i}-xs {padding-left: $i#{px} !important;}
    }
}

.last-margin-0 > *:last-child{margin-bottom: 0 !important;}

.flex-h-start{justify-content: flex-start !important;}
.flex-h-center{justify-content: center !important;}
.flex-h-end{justify-content: flex-end !important;}
.flex-h-justify{justify-content: space-between !important;}

.flex-v-start{align-items: flex-start !important;}
.flex-v-center{align-items: center !important;}
.flex-v-end{align-items: flex-end !important;}
