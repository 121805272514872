/*---Main Header
--------------------------*/
.main-header{
    background-color: $white;
    position: relative;
    z-index: 1000;
    padding: 18px 0 0;
}

/*---Header Bar
--------------------------*/
.header-bar {
    padding-top: 0 !important;
    .content-wrapper{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 18px 0;
    }

   .header-logo{
        @media(max-width:1199px){
            flex-grow: 1;
        }

        @media(max-width:767px){
            order: 0;
        }
        @media(max-width:480px){
            width: 106px;
        }
    }

    .top-nav {
        // @media(max-width:480px){
        //     margin-top: 20px;
        //     flex-grow: 1;
        //     width: 100%;
        //     .h-list{
        //         justify-content: space-between;
        //     }
        // }
        .nav-item {
            margin-right: 100px;
            @media(max-width:991px){
                margin-right: 40px;
            }
            // @media(max-width:480px){
            //     margin-right: 1em;
            // }
            &:last-child {
                margin-right: 0;
            }
            > a {
                display: inline-flex;
                align-items: center;
                font-size:16px;
                padding: 5px 0;
                i {
                    margin-right: 10px;
                    // @media(max-width:480px){
                    //     margin-right: 0.2em;
                    // }
                }
            }
            .dropdown{
                .dropdown-toggle{
                    &:before{
                        top: 0;
                    }
                }
                .dropdown-menu{
                    padding: 0;
                    li{padding: 0;}
                }
            }
        }
    }

    &.sticky{
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        // background: $white;
        box-shadow: $boxShadow;
        padding: 10px 0 !important;
        .content-wrapper{
            max-width: 980px;
            margin: 0 auto;
            padding: 0 15px;
        }
    }
}

