.stepper .StepperContainer-0-2-1{
margin-top: 42px;
height: 100px;
}
.stepper .ConnectorContainer-0-2-28 .ConnectorContainer-d14-0-2-104{
color: #687173;
}

.stepper span{
color:#687173;
font-size: 1.7rem;
line-height: 0.6;
    /* margin: 0 0 1.5rem; */
    font-family: "HK Grotesk", sans-serif;
    font-weight: 400;
}

.stepper .StepperContainer-0-2-1 button{
  background-color: #18A0FB !important;
}

