/*==== SCSS Variables ====*/
$colors: (
    primary:  #FBBA43,
    secondary: #FE0054,
    tertiary: #18A0FB,
    grey: #7A777C,
    light_grey:#F4F4F4,
    body: #ffffff,
    content: #3A4655,
    btn_border:#4D4747,
    default_border: #f2f2f2,
    warning: #ec971f,
    danger: #E9344A,
    success:#060706,
    white:#ffffff,
    black:#000000
);
$max-zIndex: 1000;
$white:#ffffff;
$black:#000000;
$primaryColor: #18A0FB;
$fontAwesome:'Font Awesome 5 Free';

$fonts: (
    primary: ('Spartan', sans-serif),
    secondary: ('HK Grotesk' , sans-serif)
);

$content-size: 16px;
$default-radius:0px;
$assetsPath: 'images';

$boxShadow: 0px 0.5px 1.5px rgba(0, 0, 0, 0.008), 0px 1.2px 3.7px rgba(0, 0, 0, 0.012), 0px 2.3px 7px rgba(0, 0, 0, 0.015), 0px 4px 12.5px rgba(0, 0, 0, 0.018), 0px 7.5px 23.4px rgba(0, 0, 0, 0.022), 0px 18px 56px rgba(0, 0, 0, 0.03);
$boxShadow2: 0px 4px 20px 1px rgba(0, 0, 0, 0.24);

