.templateTitle{
    font-size: 35px !important;
    font-weight: 600 !important;
}

.templateText{
    color: gray;
    font-size: 20px !important;
}

.templateSecondaryTitle{
    font-size: 25px !important;
    font-weight: 600 !important;
}

.white-holder{
    max-width: 570px;
    margin: 0 auto;
    min-height: 605px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
}

.margt-3{
    margin-top: 40px !important;
}

.sentRecievedGiftList{
    padding: 20px 30px !important;
    border-radius: 10px;
    background-color: #F3F7FA !important;
}

.sentRecievedIcons{
    width: 30px !important;
}

.templateThirdTitle{
    font-size: 15px !important;
    font-weight: 600 !important;
}

.margin-auto{
    margin: auto !important;
}

.templateSmallTitle{
    font-size: 15px !important;
    font-weight: 800 !important;
}

.templateSmallText{
    font-size: 15px !important;
    color: gray;
}