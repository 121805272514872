/*-- FontKit --*/
@import "base/fontkit";

/*-- Helpers: --*/
@import "helpers/mixins";
@import "helpers/utilities";
@import "helpers/variables";

/*-- Global Classes: --*/
@import "base/typography";
@import "base/global";

/*-- Layout Classes: --*/
@import "layout/header";
@import "layout/footer";

/*-- Pages Classes: --*/
@import "pages/home";
@import "pages/send-gift";
@import "pages/grey-theme";
@import "pages/reciever-home";
@import "pages/reciever-pages";
@import "pages/text-pages";
@import "pages/no-found-page";

.mainLoader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  pointer-events: none;
}

.displayNone {
  display: none;
}

.clickable {
  cursor: pointer;
}

.main-content {
  min-height: 100vh;
}

.play-video {
  video {
    width: 100%;
  }
}

.spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin-right: 10px;
  margin: 0 auto;
}

.popup-box {
  text-align: center;
  justify-content: center !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.content {
  margin-bottom: 34px;

  h1 {
    margin: 10px;
    text-align: center;
  }
  p {
    margin: 10px;
  }
}
.react-datepicker__input-container {
  input {
    height: 51px;
    width: 100%;
    border: 1px solid #edf1f9;
    border-radius: 32px !important;
    padding: 16px 20px 16px 24px;
    border-radius: 32px;
    font-size: 16px;
    line-height: 24px;
    background: $white;
    font-family: map-get($fonts, secondary);
    font-weight: normal;

    &:invalid {
      border-color: #ff9f9f;
    }

    &::-moz-placeholder {
      color: #b3bbbb;
      opacity: 1;
    }

    &::-webkit-input-placeholder {
      color: #b3bbbb;
      opacity: 1;
    }

    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
    }
  }

  input:focus {
    border: none !important;
  }
}

.react-datepicker-wrapper {
  border: none !important;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  height: 52px;
}

.react-datepicker-wrapper:focus {
  border: none !important;
}

.react-datepicker-ignore-onclickoutside {
  border: none !important;
  width: 100%;
}

.react-datepicker-ignore-onclickoutside:focus {
  border: none !important;
  width: 100%;
}

.date-picker {
  .input-item {
    position: relative;
    > img {
      position: absolute;
      right: 20px !important;
      top: 45px !important;
    }
  }
}

.no-padding {
  padding: 0 !important;
}

a {
  cursor: pointer;
}

.form-control {
  color: #33415c;
}

.danger {
  color: #e9344a;
}

.btn-link {
  svg {
    margin-right: 5px;
  }
}

@media (max-width: 575px) {
  .hide-mobile {
    display: none;
  }
}
.aaa {
  stroke: #52b8fc;
}

.bbb {
  fill: #52b8fc;
}
.ccc {
  stroke-linejoin: round;
  stroke: #52b8fc;
}

p {
    word-break: break-word;
}
.profile-load-funds {
  margin-left: 170px;
}
.section {
  margin-top: 20px;
}

.cursor-pointer{
  cursor: pointer;
}