@mixin transition($properties){
    transition: #{$properties}; -webkit-transition: #{$properties}; -moz-transition: #{$properties}; -ms-transition: #{$properties};
}
@mixin transform($properties){
    transform: #{$properties}; -webkit-transform: #{$properties}; -moz-transform: #{$properties}; -ms-transform: #{$properties};    
}
@mixin sameValue($list, $val){
    @for $i from 1 through length($list) {
        #{nth($list, $i)}: $val;
    }
}
@mixin dimension($ww, $hh){
    width: $ww;
    height: $hh;
}
@mixin image-holder($ww, $hh, $radius: 0, $bg: #dee0e5){
    width: $ww; height: $hh;
    background: $bg;
    border-radius: $radius; overflow: hidden; display: flex; align-items: center; justify-content: center;
}
@function calc-percent($target, $container) {
    @return ($target / $container) * 100%;
}