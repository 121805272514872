/*---Reciever Homepage
----------------------------*/
.reciever-home {
  background-color: $white;
  .reciever-content-wrapper {
    height: 100%;
    max-width: 570px;
    .card-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
    }
  }

  .main-header {
    background: $white;
    padding-top: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    transition: all 0.5s ease;
    .header-bar {
      .content-wrapper {
        padding: 18px 0;
      }
      .top-nav {
        @media (max-width: 575px) {
          margin-top: 15px;
        }
        .nav-item {
          margin-right: 34px;
          .dropdown-toggle {
            color: map-get($colors, grey);
          }
          > a {
            color: map-get($colors, primary);
          }
        }
      }
    }
  }

  .btn {
    height: 48px;
  }

  .card-holder {
    .main-title {
      font-size: 60px;
      line-height: 1.2;
      font-weight: 800;
      color: #52b8fc;
      font-family: map-get($fonts, primary);
      @media (max-width: 575px) {
        font-size: 40px;
      }
    }
    .btn {
      min-width: 178px;
    //   background-color: map-get($colors, content);
      color: $white;
      &:hover,
      &:focus,
      &:active {
        background-color: map-get($colors, grey);
      }
    }
    @media (max-width: 575px) {
      h1 {
        font-size: 40px;
      }
    }
  }

  .bear-card-item {
    padding-left: 40px;
    img {
      max-width: 240px;
    }
  }

  .main-content {
    padding-top: 90px;
    height: 100%;
    background: $white;
    transition: all 0.5s ease;
    .container {
      height: 100%;
    }
    #my-canvas {
      position: absolute;
      z-index: -11;
      width: 100%;
      // height: 100%;
    }
    @media (max-width: 480px) {
      padding-top: 96px;
    }
  }
}
