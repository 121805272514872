/*---Reciever Homepage
----------------------------*/
.reciever-home{
    background-color: $white;
    .main-header{
        background: $white;
        padding-top: 0;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 999;
        transition: all 0.5s ease;
        .header-bar{
            .content-wrapper{
                padding: 18px 0;
            }
            .top-nav {
                @media(max-width:575px){
                    margin-top: 15px;
                }
                .nav-item {
                    margin-right: 34px;
                    .dropdown-toggle{color: map-get($colors, grey );}
                    > a{ color: map-get($colors, primary ); }
                }
            }
        }
    }

    .btn{height: 48px;}

    .card-holder{
        .main-title{
            font-size: 60px; line-height: 1.2; font-weight: 800; color: #52B8FC; font-family: map-get($fonts, primary);
            @media(max-width:575px){
                font-size: 40px;
            }
        }
        .btn{
            min-width: 178px; 
            color: $white;
            &:hover,&:focus,&:active{
                // background-color: map-get($colors, primary );
            }
        }
        @media(max-width:575px){
            h1{font-size: 40px;}
        }
    }

    .bear-card-item{
        padding-left: 40px;
        img{max-width: 240px;}
    }

    .main-content{
        padding-top: 90px;
        height: 100%;
        background: $white;
        transition: all 0.5s ease;
        @media(max-width:480px){
            padding-top: 96px;
        }
    }
}

/*---Reciever Other Pages
----------------------------*/
.reciever-pages{
    .max-w-740 {
        .reciever-content-wrapper{
            max-width: 570px;
        }
    }
    .max-w-1070 {
        .reciever-content-wrapper{
            max-width: 900px;
        }
    }
    .main-content {
        .container{
            height: 100%;
        }
        .reciever-content-wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}

/*---Reciever Content
----------------------------*/
.reciever-content-wrapper-main{
    text-align: center;
}
.reciever-content-wrapper{
    max-width: 370px;
    margin: 0 auto;
    .btn{
        height: 48px; font-size: 14px; font-weight: 600; font-family: map-get($fonts, primary); min-width: 138px;
        padding: 10px 24px;
    }
    .btn-wrapper{
        .btn{
            height: 34px;
            &.btn-transparent{
                border-color: map-get($colors, grey );
                color: map-get($colors, grey);
                &:hover,&:focus,&:active,&.active{
                    // background-color: map-get($colors, grey );
                    color: $white;
                }
            }
        }
    }
    .card-holder{
        width: 100%;
        .text-box{
            padding: 16px 24px;
            margin-bottom: 40px;
            border-radius: 16px;
            h5{color: #33415C;}
            p {
                line-height: 24px;
                color: map-get($colors, content );
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 25px;
                color: map-get($colors, grey);
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .dropdown{
            .dropdown-toggle{
                font-size: 26px;
                font-family: map-get($fonts, primary);
                color: map-get($colors, content );
                font-weight: 600;
                align-items: center;
                display: flex;
                border-bottom: 1px solid #EDF1F9;
                padding-bottom: 10px;
                span{flex-grow: 1;}
                &:before{
                    align-items: center;
                    position: static;
                    order: 1;
                    font-size: 26px;
                    color: map-get($colors, primary );
                }
            }
            .dropdown-menu{
                width: 100%;
                &.show{margin-top: 0;}
                .dropdown-item{
                    a{
                        padding: 0;
                        &:hover,&:focus{color: map-get($colors, grey); background-color: transparent;}
                    }
                }
            }
        }
        .title-block{
            h1{font-weight: 800; font-size: 36px;}
        }
    }

    .video-wrapper{
        min-height: 230px;
        display: flex;
        align-items: center;
        justify-content: center;
        .video-banner{
            width: 100%; height: 100%; text-align: center;
            .action-btn{color: map-get($colors, primary); font-size: 40px;}
            video{
                width: 100%
            }
        }
    }

    .card-wrapper{
        .content-holder{
            border-radius: 5px;
            .box-content{
                min-height: 294px;
                width: 100%;
            }
        }
        .apply-giftcard{
            h4{color: $black; line-height: 1.4;}
            .price-amount{font-weight: 400; color: $black;}
        }
    }

    .text-block{
        h5{color: #33415C; font-size: 16px; margin-bottom: 10px; font-weight: 700; line-height: 1.5;}

        h2{
            color: $black; font-size: 20px; line-height: 1.5; font-family: map-get($fonts, primary ); margin-bottom: 0;
        }

        p{
            margin-bottom: 20px; font-size: 16px; color: #33415C; font-family: map-get($fonts , secondary); font-weight: 400;
            a{
                color: map-get($colors, grey);
                &:hover,&:focus,&:active{
                    color: map-get($colors, primary );
                }
            }
        }

        .btn-wrapper{
            .btn-list{
                flex-wrap: wrap;
                .btn{
                    margin:0 10px 10px 0; padding: 10px 20px; font-size: 12px;
                    &:last-child{margin-right: 0;}
                }
            }
        }

        .search-form{
            .search-item-wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .search-item{
                flex-grow: 1;
                position: relative;
                .form-control{
                    background-color: transparent;
                    padding-left: 50px;
                    border-radius: 5px 0 0 5px;
                    height: 47px;
                }
                .search-btn{
                    position: absolute;
                    top: 10px;
                    left: 18px;
                    color: #7D8597;
                    width: 18px;
                    i{display: inline-block;}
                }
            }
            .btn{
                border-radius: 0 32px 32px 0; height: 47px; width: 100%; min-width: 114px; border-radius: 0px 5px 5px 0px;
                background: map-get($colors, grey); color: $white;
                &:hover,&:focus,&:active{
                    background: map-get($colors, content);
                }
                @media(max-width:991px){
                    min-width: 120px;
                }
                @media(max-width:575px){
                    min-width: inherit;
                }
            }
        }

        .related-searches{
            .related-search-wrapper{
                display: flex;
                flex-wrap: wrap;
                .search-list-item{
                    margin-right: 15px;
                    margin-bottom: 15px;
                    .btn{
                        border-radius: 20px;
                        font-size: 14px;
                        font-weight: 500;
                        padding: 0 20px;
                        height: 38px;
                        min-width: 114px;
                        line-height: 1;
                        &:hover,&:focus,&:active,&.active{
                            background: #7D8597;
                            border-color: #7D8597;
                            color: $white;
                        }
                    }
                }
            }
        }

        h5{color: #000; font-weight: 700; margin: 0 0 14px; font-size: 16px;}
        .category-searched-list{
            .image-holder{
                border-radius: 5px;
                margin-bottom: 10px;
                overflow: hidden;
                .box{
                    background: #E7E7E7;
                    min-height: 120px;
                }
            }
            .content-holder{
                .title-header{
                    h5 {color: #33415C;}
                }
                margin-bottom: 40px;
                h5{font-size: 14px; color: map-get($colors, grey); font-weight: 700; margin: 0 0 4px; font-weight: 600;}
                p{font-size:14px; font-weight: 400; line-height: 20px;}
            }

            .box-checkbox{position: relative;}
            .box-checkbox input{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
                opacity: 0;
                z-index: 100;
            }
            .box-checkbox .box{
                background: #E7E7E7;
                min-height: 120px;
                min-width: 230px;
                width: 100%;
                border-radius: 5px;
                position: relative;
                &:after{
                    content: "";
                    background:url('../../images/icon/tick-selected.svg') no-repeat 0 0;
                    width: 26px;
                    height: 26px;
                    display: block;
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    opacity: 0;
                    transition: all 0.5s ease;
                }
            }
            .box-checkbox input:checked + .box{
                background-color: map-get($colors, grey);
                &:after{
                    opacity: 1;
                    transition: all 0.5s ease;
                }
            }
        }
    }
}

/*---Reciever Card Content
----------------------------*/
.card-content-wrapper {
    .card-item {
        .radio-btn-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 30px;
            .form-check-label{
                font-size: 15px;
                font-family: map-get($fonts, secondary);
                color: #33415C;
                cursor: pointer;
                input + span {display: inline-block; margin-left: 10px;}
            }
        }
        .search-form{
            .search-item-wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media(max-width:480px){
                    flex-wrap: wrap;
                    .btn-search {
                        width: 100%;
                    }
                }
            }
            .search-item{
                flex-grow: 1;
                position: relative;
                .form-control{
                    background-color: transparent;
                    padding-left: 50px;
                    border-radius: 4px 0 0 4px !important;
                    height: 47px;
                    @media(max-width:480px){
                        border-radius: 4px !important;
                        margin-bottom: 10px;
                    }
                }
                .search-btn{
                    position: absolute;
                    top: 10px;
                    left: 18px;
                    color: #7D8597;
                    width: 18px;
                    i{display: inline-block;}
                }
            }

            .select-item{
                @media(max-width:480px){
                    min-width: 100%;
                    margin-bottom: 10px;
                    .form-control{
                        border-radius: 4px !important;
                        padding: 0 20px;
                    }
                    &:after{
                        right: 20px;
                    }
                }
            }

            .btn{
                border-radius: 0 4px 4px 0; height: 47px; width: 100%; min-width: 114px;
                @media(max-width:991px){
                    min-width: 120px;
                }
                @media(max-width:575px){
                    min-width: inherit;
                }
                @media(max-width:480px){
                    border-radius: 4px;
                }
            }
        }

        .related-searches{
            .related-search-wrapper{
                display: flex;
                flex-wrap: wrap;
                .search-list-item{
                    margin-right: 15px;
                    margin-bottom: 15px;
                    .btn{
                        border-radius: 20px;
                        font-size: 14px;
                        font-weight: 500;
                        padding: 0 20px;
                        height: 38px;
                        min-width: 95px;
                        line-height: 1;
                        &.active{
                            background-color: map-get($colors, primary);
                            border-color: map-get($colors, primary);
                            color: $white;
                        }
                    }
                }
            }
        }

        h5{color: #33415C; font-weight: 700; margin: 0 0 14px; font-size: 16px;}
        .category-searched-list{
            .image-holder{
                border-radius: 5px;
                margin-bottom: 10px;
                overflow: hidden;
                .box{
                    background: #E7E7E7;
                    min-height: 120px;
                }
            }
            .content-holder{
                margin-bottom: 40px;
                h5{font-size: 14px; color: #33415C; margin: 0 0 4px; font-weight: 600;}
                p{font-size:14px; font-weight: 400; line-height: 20px;}
            }
        }

        .card-holder .see-all-dropdown .dropdown-toggle {
            font-size: 14px;
            color: map-get($colors, primary);
            border-bottom: none;
            padding-bottom: 0;
            font-weight: 600;
            font-family: map-get($fonts, secondary);
            &:before{font-size: 15px;}
            span {
                display: inline-block;
                text-align: right;
                margin-right: 5px;
            }
        }
    }
}