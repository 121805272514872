/*-- FontKit --*/
@font-face {
    font-family: 'HK Grotesk';
    src: url('../fonts/HKGrotesk-Regular.eot');
    src: url('../fonts/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HKGrotesk-Regular.woff2') format('woff2'),
        url('../fonts/HKGrotesk-Regular.woff') format('woff'),
        url('../fonts/HKGrotesk-Regular.ttf') format('truetype'),
        url('../fonts/HKGrotesk-Regular.svg#HKGrotesk-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('../fonts/HKGrotesk-Light.eot');
    src: url('../fonts/HKGrotesk-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HKGrotesk-Light.woff2') format('woff2'),
        url('../fonts/HKGrotesk-Light.woff') format('woff'),
        url('../fonts/HKGrotesk-Light.ttf') format('truetype'),
        url('../fonts/HKGrotesk-Light.svg#../fonts/HKGrotesk-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('../fonts/HKGrotesk-Medium.eot');
    src: url('../fonts/HKGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HKGrotesk-Medium.woff2') format('woff2'),
        url('../fonts/HKGrotesk-Medium.woff') format('woff'),
        url('../fonts/HKGrotesk-Medium.ttf') format('truetype'),
        url('../fonts/HKGrotesk-Medium.svg#HKGrotesk-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('../fonts/HKGrotesk-SemiBold.eot');
    src: url('../fonts/HKGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HKGrotesk-SemiBold.woff2') format('woff2'),
        url('../fonts/HKGrotesk-SemiBold.woff') format('woff'),
        url('../fonts/HKGrotesk-SemiBold.ttf') format('truetype'),
        url('../fonts/HKGrotesk-SemiBold.svg#HKGrotesk-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('../fonts/HKGrotesk-Bold.eot');
    src: url('../fonts/HKGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HKGrotesk-Bold.woff2') format('woff2'),
        url('../fonts/HKGrotesk-Bold.woff') format('woff'),
        url('../fonts/HKGrotesk-Bold.ttf') format('truetype'),
        url('../fonts/HKGrotesk-Bold.svg#../fonts/HKGrotesk-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('../fonts/HKGrotesk-ExtraBold.eot');
    src: url('../fonts/HKGrotesk-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HKGrotesk-ExtraBold.woff2') format('woff2'),
        url('../fonts/HKGrotesk-ExtraBold.woff') format('woff'),
        url('../fonts/HKGrotesk-ExtraBold.ttf') format('truetype'),
        url('../fonts/HKGrotesk-ExtraBold.svg#../fonts/HKGrotesk-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('../fonts/HKGrotesk-Black.eot');
    src: url('../fonts/HKGrotesk-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HKGrotesk-Black.woff2') format('woff2'),
        url('../fonts/HKGrotesk-Black.woff') format('woff'),
        url('../fonts/HKGrotesk-Black.ttf') format('truetype'),
        url('../fonts/HKGrotesk-Black.svg#../fonts/HKGrotesk-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
