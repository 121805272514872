* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
}

#notfound {
    position: relative;
    height: 100vh;
    background-color: #f1f1f1;}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
}

.notfound .notfound-404 {
    position: relative;
    height: 240px;
}

.notfound .notfound-404 h1 {
    font-family: map-get($fonts, secondary);
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 252px;
    font-weight: 900;
    margin: 0;
    color: #FBBA43;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px;
}

.notfound .notfound-404 h1>span {
    text-shadow: -8px 0 0 #fff;
}

.notfound .notfound-404 h3 {
    font-family: map-get($fonts, primary);
    position: relative;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #262626;
    margin: 0;
    letter-spacing: 3px;
    padding-left: 6px;
}

.notfound h2 {
    font-family: map-get($fonts, primary);
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    margin-top: 0;
    margin-bottom: 25px;
}

@media (max-width:767px) {
    .notfound {
        padding: 0 15px;
    }

    .notfound .notfound-404 {
        height: 200px;
    }

    .notfound .notfound-404 h1 {
        font-size: 200px;
        letter-spacing: -28px;
    }
}

@media (max-width:480px) {
    .notfound .notfound-404 {
        height: 162px;
    }

    .notfound .notfound-404 h1 {
        font-size: 162px;
        height: 150px;
        line-height: 162px;
    }

    .notfound h2 {
        font-size: 16px;
    }
}