.text-pages {
    .main-content {
        background: #fff;
        padding-top: 60px;
    }
}

.content-banner-section {
    background:url('../../images/hero-banner-image.png') no-repeat top center;
    background-size: contain;
    width: 100%;
    min-height: 410px;
    position: relative;
    margin-bottom: 40px;

    @media(min-width:1366px){
        min-height: 470px;
    }

    @media(max-width:1199px){
        min-height: 320px;
        margin-bottom: 30px;
        background-size: cover;
    }

    .intro-holder {
        max-width: 950px;
        padding:  0 15px;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;

        .title {
            font-size: 38px;
            line-height: 48px;
            font-weight: 400;
            color: $white;
            letter-spacing: 1.425px;
            margin-bottom: 10px;

            @media (max-width:575px){
                font-size: 30px;
            }
        }

        .text-info {
            color: $white !important;
            font-size: 17px;
            line-height: 27px;
            font-weight: 400;
            letter-spacing: 0.6px;

            @media (max-width:575px){
                font-size: 14px;
            }
        }
    }
}

.content-block-section {
    max-width: 750px;
    padding: 50px;
    margin: 0 auto;

    @media(max-width:767px){
        padding: 30px;
    }

    .text-block {
        margin-bottom: 20px;
        h3 {
            font-size: 18px;
            line-height: 26px;
            font-weight: 400;
            letter-spacing: 0.675px;
            color: #212529;
            margin-bottom: 10px;
        }

        p {
            font-family: map-get($fonts, secondary);
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            letter-spacing: 0.6px;
            color: #7D8597;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}


.faq-section {
    .panel-group {
        margin-bottom: 0;
    }

    #accordion .panel {
        border: medium none;
        border-radius: 0;
        box-shadow: none;
        margin: 0 0 15px 10px;
    }

    #accordion .panel-heading {
        border-radius: 30px;
        padding: 0;
    }

    #accordion .panel-title a {
        background: #ffb900 none repeat scroll 0 0;
        border: 1px solid transparent;
        border-radius: 30px;
        color: #fff;
        display: block;
        font-size: 18;
        font-weight: 500;
        padding: 15px 30px;
        position: relative;
        transition: all 0.3s ease 0s;
    }

    #accordion .panel-title a.collapsed {
        background: #fff none repeat scroll 0 0;
        border: 1px solid #f1f1f1;
        box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.04);
        color: #333;
    }

    #accordion .panel-title a::after,
    #accordion .panel-title a.collapsed::after {
        background: #ffb900 none repeat scroll 0 0;
        border: 1px solid transparent;
        border-radius: 0;
        color: #fff;
        content: "\f107";
        font-family: 'Font Awesome 5 Free';
        display: inline-block;
        font-size: 18px;
        font-weight: 900;
        left: auto;
        right: 20px;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease 0s;
        width: 20px;
        height: 20px;
        line-height: 20px;

    }

    #accordion .panel-title a.collapsed::after {
        background: #fff none repeat scroll 0 0;
        border: none;
        box-shadow: none;
        color: #333;
        content: "\f106";
    }

    #accordion .panel-body {
        background: transparent none repeat scroll 0 0;
        border-top: medium none;
        padding: 20px 25px 10px 9px;
        position: relative;
    }

    #accordion .panel-body p {
        border-left: 1px dashed #8c8c8c;
        padding-left: 25px;
        font-size: 14px;
        font-family: map-get($fonts, secondary);
    }
}