html {
    font-size: 10px;
}

body {
    font-size: $content-size;
    font-family: map-get($fonts, primary);
    color: map-get($colors, content);
    background: map-get($colors, body);
    font-weight: 400;
    margin: 0;
    padding: 0;
}

html,body{
    height: 100%;
}

figure {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    margin: 0 0 1.5rem;
    line-height: 1.3;
    font-family: map-get($fonts, primary);
}

a {
    color: map-get($colors, primary);
    transition: all 0.3s ease;

    &:hover {
        color: darken(map-get($colors, primary), 10%);
        text-decoration: none;
    }

    &:active {
        color: darken(map-get($colors, primary), 10%);
    }
}

h1 {
    font-size: 3.5rem;
    font-weight: 700;
}

h2 {
    font-size: 26px;
    line-height: 29px;
    color: map-get($colors, content);
    font-weight: 600;
}

h3 {
    font-size: 24px;
    color: $black;
    font-weight: 700;
}

h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    font-family: map-get($fonts, primary );
}

h5 {
    font-size: 16px;
}

p {
    line-height: 1.4;
    margin: 0 0 1.5rem;
    font-family: map-get($fonts, secondary);
    font-weight: 400;
    font-size: 16px;
}